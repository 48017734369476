<template>
  <v-container fluid class="ma-0 pa-0" :class="{'light-background': $vuetify.breakpoint.smAndDown, 'white': $vuetify.breakpoint.mdAndUp}">
    <v-layout row wrap :pa-5="$vuetify.breakpoint.smAndUp" :pa-4="$vuetify.breakpoint.xsOnly">
      <v-flex xs12 max-width-1400 full-width ma-auto>
        <v-container fluid ma-0 pa-0 grid-list-lg>
          <v-layout row wrap>
            <v-flex xs12 md8 :white="$vuetify.breakpoint.smAndDown">
              <v-container fluid ma-0 pa-0 grid-list-lg>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-card :color="$vuetify.breakpoint.smAndDown ? 'white' : 'light-background'" flat tile height="100%">
                      <v-card-text>
                        <v-layout row wrap>
                          <v-flex xs12 sm4>
                            <v-img
                              v-if="policy.company_internal_name"
                              max-width="250px"
                              contain
                              :src="company_logo"
                              :lazy-src="company_logo"
                              :class="{'ma-auto': $vuetify.breakpoint.smAndDown}"
                            />
                          </v-flex>

                          <v-flex xs12 sm8>
                            <div v-if="policy.company_internal_name">
                              <p class="graphik-bold font-17 mb-2 dark-grey--text">{{ policy.company_name }}</p>

                              <v-layout row wrap>
                                <v-flex xs12 v-for="(phone, idx) in sinisters[policy.company_internal_name].phones" :key="idx">
                                  <p class="dark-grey--text graphik-medium font-15 mb-0">{{ phone.title }}</p>

                                  <p
                                    v-for="(p, i) in phone.text" :key="idx + 'p' + i"
                                    class="dark-grey--text graphik-light font-15 mb-0"
                                  >{{ p }}</p>
                                </v-flex>
                              </v-layout>

                              <v-layout row wrap>
                                <v-flex xs12>
                                  <p class="primary--text graphik-medium font-15 mb-0" v-if="sinisters[policy.company_internal_name].app">{{ $ml.get('sinisters_app') }} {{ sinisters[policy.company_internal_name].app }}</p>
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 md6>
                    <v-card :color="$vuetify.breakpoint.smAndDown ? 'white' : 'light-background'" flat tile height="100%">
                      <v-card-text>
                        <p class="graphik-bold font-20 mb-1 dark-grey--text">{{ $ml.get('fields_policy') }}:</p>
                        <p class="graphik-light font-20 mb-0 dark-grey--text">{{ policy.number }}</p>
                      </v-card-text>
                    </v-card>
                  </v-flex>

                  <v-flex xs12 md6>
                    <v-card :color="$vuetify.breakpoint.smAndDown ? 'white' : 'light-background'" flat tile height="100%">
                      <v-card-text>
                        <p class="graphik-medium font-17 mb-1 dark-grey--text">{{ $ml.get('fields_validity') }}:</p>
                        <p class="graphik-light font-17 mb-0 dark-grey--text" v-if="policy.start_date">{{ policy.start_date | moment('LL') }} {{ $ml.get('general_to') }} {{ policy.end_date | moment('LL') }}</p>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <v-card :color="$vuetify.breakpoint.smAndDown ? 'white' : 'light-background'" flat tile height="100%">
                      <v-card-text>
                        <p class="graphik-medium font-17 mb-1 dark-grey--text">{{ $ml.get('fields_insurance_type') }}:</p>
                        <p class="graphik-light font-17 mb-0 dark-grey--text">{{ policy.policy_type }}</p>
                      </v-card-text>
                    </v-card>
                  </v-flex>

                  <v-flex xs12 md5>
                    <v-card :color="$vuetify.breakpoint.smAndDown ? 'white' : 'light-background'" flat tile height="100%">
                      <v-card-text>
                        <p class="graphik-medium font-17 mb-1 dark-grey--text">{{ $ml.get('fields_vehicle') }}:</p>
                        <p class="graphik-light font-17 mb-0 dark-grey--text">{{ policy.car }}</p>
                      </v-card-text>
                    </v-card>
                  </v-flex>

                  <v-flex xs12 md3>
                    <v-card :color="$vuetify.breakpoint.smAndDown ? 'white' : 'light-background'" flat tile height="100%">
                      <v-card-text>
                        <p class="graphik-medium font-17 mb-1 dark-grey--text">{{ $ml.get('fields_policy_type') }}:</p>
                        <p class="graphik-light font-17 mb-0 dark-grey--text">{{ policy.product_name }}</p>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12>
                    <v-card :color="$vuetify.breakpoint.smAndDown ? 'white' : 'light-background'" flat tile height="100%">
                      <v-card-text>
                        <p class="graphik-bold font-14 mb-0 dark-grey--text">{{ $ml.get('policy_insured_data') }}</p>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12>
                    <v-card flat tile height="100%" color="transparent">
                      <v-card-text class="pt-0">
                        <p class="graphik-medium font-17 dark-grey--text">{{ $ml.get('fields_inssured') }}: <span class="graphik-light">{{ policy.full_name }}</span></p>

                        <p class="graphik-medium font-17 dark-grey--text">{{ $ml.get('fields_contract') }}: <span class="graphik-light">{{ policy.full_name_contract }}</span></p>

                        <p class="graphik-medium font-17 dark-grey--text">{{ $ml.get('fields_address') }}: <span class="graphik-light">{{ policy.address }}</span></p>

                        <p class="graphik-medium font-17 dark-grey--text">{{ $ml.get('fields_payment_method') }}: <span class="graphik-light">{{ policy.frequency_name }}</span></p>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>

            <v-flex xs12 md4>
              <v-card color="light-background" flat tile>
                <v-card-text>
                  <v-layout row wrap pa-3>
                    <v-flex xs12 d-flex>
                      <p class="graphik-bold font-25 mb-0 primary--text">{{ $ml.get('policy_download') }}</p>

                      <v-btn 
                        color="primary"
                        depressed
                        :loading="g_isLoading"
                        class="normalcase graphik-bold-italic ma-0 border-radius-5"
                        @click="openDocument"
                        :disabled="!policy.merge_pdf"
                      >{{ $ml.get('policy_document') }}</v-btn>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12 :text-xs-right="$vuetify.breakpoint.mdAndUp" :text-xs-center="$vuetify.breakpoint.smAndDown">
              <v-btn 
                color="pantene"
                depressed dark
                :loading="g_isLoading"
                class="normalcase graphik-bold-italic ma-0 border-radius-5"
                @click="g_auth.access_token ? $goTo('/perfil/polizas', 'goto_profile_policies') : $goTo('/buscar/poliza', 'goto_searchpolicy')"
              ><v-icon dark small class="mr-2">arrow_back</v-icon>{{ $ml.get('general_back') }}</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <message-dialog :dialog="g_dialog"></message-dialog>
    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'Policy',
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_POLICY,
    meta: [
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_POLICY
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_POLICY
      }
    ]
  },
  data () {
    return {
      policy: {},
      sinisters: {
        qualitas: {
          app: 'Q Movil',
          phones: [
            {
              title: 'Qualitas',
              text: ['01 800 288 6700', '01 800 800 2880']
            }
          ]
        },
        gnp: {
          app: 'GNP Movil',
          phones: [
            {
              title: 'GNP',
              text: ['01 800 400 9000']
            }
          ]
        },
        chubb: {
          app: 'ABA Clientes',
          phones: [
            {
              title: 'Aba | Chubb',
              text: []
            },
            {
              title: 'Auto',
              text: ['Cd. de México, Monterrey y Guadalajara', 'Teléfono: 1253 3030', 'Resto del país: 01 800 834 3400']
            },
            {
              title: 'Hogar',
              text: ['Reporte de Siniestro: Servicio a Clientes:', 'Cd. de México, Monterrey y Guadalajara', 'Teléfono: 1253 3737', 'Resto del país: 01 800 716 5467']
            }
          ]
        },
        hdi: {
          app: 'HDI Contigo',
          phones: [
            {
              title: 'HDI Seguros',
              text: ['01 800 019 6000']
            }
          ]
        },
        ana: {
          app: 'ANA GO',
          phones: [
            {
              title: 'ANA Seguros',
              text: ['Cd. De México 5322 8282', 'Nacional 01 800 911 2627', 'Internacional 001 52 55 5322 8282']
            }
          ]
        },
        afirme: {
          app: 'Afirme Seguros',
          phones: [
            {
              title: 'AFIRME Seguros',
              text: ['Siniestros:  01 800 723 4763', 'Atención a Clientes: 01 800 734 8761']
            }
          ]
        },
        mapfre: {
          app: 'Mapfre me ayuda',
          phones: [
            {
              title: 'Mapfre',
              text: []
            },
            {
              title: 'Auto',
              text: ['01 800 062 7373']
            },
            {
              title: 'Gastos Médicos Mayores',
              text: ['Cd. México 01 55 5246 7502', 'Nacional 01 800 365 0024']
            }
          ]
        },
        primeroSeguros: {
          app: '',
          phones: [
            {
              title: 'Primero Seguros',
              text: ['01 899 917 6288']
            }
          ]
        },
        axa: {
          app: 'My AXA México',
          phones: [
            {
              title: 'AXA Seguros',
              text: ['01 800 911 1292']
            }
          ]
        }
      }
    }
  },
  computed: {
    company_logo () {
      if (!this.policy.company_internal_name) {
        return null
      }
      return require('@/assets/img/companies/' + this.policy.company_internal_name + '-full.png')
    }
  },
  methods: {
    // CRUD
    getPolicy () {
      this.g_isLoading = true
      this.$api.getPolicy(
        this.$route.params.policy,
        response => {
          this.policy = response.body.data
          this.g_isLoading = false
        },
        response => this.$errorHandling(response).then(() => {
          this.getPolicy()
        }, () => {})
      )
    },
    openDocument () {
      window.open(this.policy.merge_pdf, '_blank')
    }
  },
  mounted () {
    this.getPolicy()
  }
}
</script>

<style scoped>

</style>
